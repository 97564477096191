import { useState, useEffect } from "react";
import { doc, updateDoc, getDoc } from "firebase/firestore"; // Firestore
import { db, auth } from "../../../firebaseConfig"; // Firebase config
import {
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth"; // Firebase auth functions
import { toast } from "react-toastify";
import {
  Flex,
  Text,
  Button,
  Input,
  FormControl,
  FormLabel,
  Tooltip,
} from "@chakra-ui/react"; // Utilise Chakra ou tout autre style
import { useAuth } from "../../context/AuthContext";
import { useParams } from "react-router-dom";
import deepEqual from "deep-equal"; // Utiliser deep-equal pour vérifier les changements
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import PasswordModal from "./PasswordModal";

const AccountDetails = () => {
  const { restaurantId } = useParams();
  const {
    updateUserDetails,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phone,
    setPhone,
    address,
    setAddress,
  } = useAuth();
  const restaurant = restaurantId || sessionStorage.getItem("restaurantId");

  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [hasPasswordProvider, setHasPasswordProvider] = useState(false); // Indique si l'utilisateur a le provider "password"
  const [hasGoogleProvider, setHasGoogleProvider] = useState(false); // Indique si l'utilisateur a le provider "google.com"
  const [showPassword, setShowPassword] = useState(false);
  const [isModified, setIsModified] = useState(false); // Indique si des changements ont été effectués
  const [initialData, setInitialData] = useState(null); // Initialise initialData à null jusqu'à ce qu'il soit prêt
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false); // Gérer l'état de la modale
  const openPasswordModal = () => setIsPasswordModalOpen(true);
  const closePasswordModal = () => setIsPasswordModalOpen(false);


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = auth.currentUser;
        setCurrentUser(user);

        if (user) {
          // Vérifier les providers pour l'utilisateur
          const providerIds = user.providerData.map((provider) => provider.providerId);
          setHasPasswordProvider(providerIds.includes("password"));
          setHasGoogleProvider(providerIds.includes("google.com"));

          const userRef = doc(db, `restaurants/${restaurant}/customers`, user.uid);
          const docSnap = await getDoc(userRef);

          if (docSnap.exists()) {
            const data = docSnap.data();
            setFirstName(data.firstName || "");
            setLastName(data.lastName || "");
            setPhone(data.phone || "");
            setAddress(data.address || "");
            setInitialData({ firstName: data.firstName, lastName: data.lastName, phone: data.phone, address: data.address });
          } else {
            console.log("No such document!");
          }
        }
      } catch (error) {
        console.error("Error fetching user data: ", error);
      }
    };

    fetchUserData();
  }, [restaurant]);

    // Vérification des changements avec deepEqual
    useEffect(() => {
      if (!initialData) return;
  
      const currentData = { firstName, lastName, phone };
      const hasChanges = !deepEqual(currentData, initialData);
      setIsModified(hasChanges);
    }, [firstName, lastName, phone, initialData]);

  const saveUserDetails = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Mise à jour des informations personnelles dans Firestore
      const userRef = doc(
        db,
        `restaurants/${restaurant}/customers`,
        currentUser?.uid
      );
      await updateDoc(userRef, {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
      });
      updateUserDetails({
        firstName: firstName,
        lastName: lastName,
        phone: phone,
      });
      toast.success("User details updated successfully");

      // Met à jour `initialData` après la sauvegarde réussie
      setInitialData({ firstName, lastName, phone });
    } catch (error) {
      console.error("Error updating user details:", error);
      toast.error("An error occurred while updating your details.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex
      direction="column"
      flexGrow="1"
      flex={1}
      minW="0"
      gap={{ desktop: 8, mobile: 8 }}>
      <Flex
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "40%", mobile: "100%" }}
          gap={1}>
          <Text color={"var(--grey700)"} fontSize={"16px"} fontWeight={"600"}>
            Personal details
          </Text>
          <Text maxW={"320px"} color={"#445b78"}>
            Enter your personal details.
          </Text>
        </Flex>

        <Flex direction="column" gap={2} flex={1}>
          <Tooltip label="Email address cannot be modified">
            <FormControl>
              <FormLabel fontSize={"12px"} mb={1}>
                Email address
              </FormLabel>
              <Input type="email" value={currentUser?.email} isDisabled />
            </FormControl>
          </Tooltip>

          <Flex gap={2} direction={{ desktop: "row", mobile: "column" }}>
            <FormControl>
              <FormLabel fontSize={"12px"} mb={1}>
                First name
              </FormLabel>
              <Input
                focusBorderColor="#121212"
                name="firstName"
                type="text"
                placeholder="First name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </FormControl>

            <FormControl>
              <FormLabel fontSize={"12px"} mb={1}>
                Last name
              </FormLabel>
              <Input
                focusBorderColor="#121212"
                name="lastName"
                type="text"
                placeholder="Last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </FormControl>
          </Flex>

          <FormControl>
            <FormLabel fontSize={"12px"} mb={1}>
              Phone
            </FormLabel>
            <PhoneInput
              country={"lu"}
              value={phone}
              onChange={(value) => setPhone(value)} // Utilisez 'value' directement
              containerStyle={{ width: "100%", marginTop: "8px" }}
              inputStyle={{
                width: "100%",
                height: "37.5px",
                border: "1px solid var(--borders)",
                background: "transparent",
                fontWeight: "400 !important",
                fontFamily: "'Inter', sans-serif",
              }}
            />
          </FormControl>
        </Flex>
      </Flex>

      <Flex
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "40%", mobile: "100%" }}
          gap={1}>
          <Text color={"var(--grey700)"} fontSize={"16px"} fontWeight={"600"}>
            Password
          </Text>
          <Text maxW={"280px"} color={"#445b78"}>
            {hasGoogleProvider && !hasPasswordProvider
              ? "Create a password for your account."
              : "Update your password."}
          </Text>
        </Flex>
        <Flex direction="column" gap={2}>

          <Button size={"md"} variant={"outline"} onClick={openPasswordModal}>{hasPasswordProvider ? "Update" : "Create"} password</Button>

          
          
        </Flex>
      </Flex>

{/*}
      <Flex
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "40%", mobile: "100%" }}
          gap={1}>
          <Text color={"var(--grey700)"} fontSize={"16px"} fontWeight={"600"}>
            Payment methods
          </Text>
          <Text maxW={"280px"} color={"#445b78"}>
            Add payment methods to your account for a faster checkout experience.
          </Text>
        </Flex>
        <Flex direction="column" gap={2} flex={1}>
        <Flex style={{width: '100%', height: '100%', padding: 16, background: 'white', borderRadius: 8, border: '1px #E9EAEB solid', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 16}}>
    <div style={{flex: '1 1 0', height: 68, justifyContent: 'flex-start', alignItems: 'flex-start', gap: 16, display: 'flex'}}>
        <div style={{width: 58, height: 40, position: 'relative', background: 'white', borderRadius: 6, border: '1px #E9EAEB solid'}}>
            <img style={{width: 39.67, height: 13.03, left: 8, top: 13.50, position: 'absolute'}} src="https://via.placeholder.com/40x13" />
        </div>
        <Flex style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8 }}>
            <Flex style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 8 }}>
                <Flex style={{flex: '1 1 0', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <div style={{alignSelf: 'stretch', color: '#414651', fontSize: 14, fontFamily: 'Inter', fontWeight: '500',  wordWrap: 'break-word'}}>Visa ending in 1234</div>
                    <div style={{alignSelf: 'stretch', color: '#535862', fontSize: 14, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Expiry 06/2025</div>
                </Flex>
                <Button size="xs" variant={'outline'} fontSize={'12px !important'}>Edit</Button>
            </Flex>
           
        </Flex>
    </div>
</Flex>



        </Flex>
      </Flex>
*/}

      <Flex
        flex={1}
        bg={"white"}
        position={"sticky"}
        py={4}
        justifyContent={"flex-end"}
        bottom={"0"}>
        <Button
          onClick={saveUserDetails}
          variant={"solid"}
          bg={"var(--brand)"}
          color={"white"}
          w={{ desktop: "40%", mobile: "100%" }}
          isLoading={isLoading}
          isDisabled={!isModified || isLoading} // Bouton désactivé si aucun changement ou en cours de chargement
        >
          Save changes
        </Button>
      </Flex>

      <PasswordModal  isOpen={isPasswordModalOpen} onClose={closePasswordModal} hasPasswordProvider={hasPasswordProvider} hasGoogleProvider={hasGoogleProvider} currentUser={currentUser} />
    </Flex>
  );
};

export default AccountDetails;
