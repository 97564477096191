import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { doc, onSnapshot, getDoc } from "firebase/firestore"; // Utilise onSnapshot pour les mises à jour en temps réel
import { db } from "../../../firebaseConfig";
import {
  Flex,
  Box,
  Text,
  Heading,
  Spinner,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Image,
  AccordionIcon,
  Button,
  IconButton,
  Tooltip,
  ListItem,
  List
} from "@chakra-ui/react";
import { useAuth } from "../../context/AuthContext";
import { Phone, FileText } from "lucide-react";
import styles from "../../shop.module.css";
import OrderInvoice from "./OrderInvoice";
import { pdf } from "@react-pdf/renderer";
import OrderSteps from "./OrderSteps";

const OrderDetails = () => {
  const { restaurantId, orderId } = useParams();
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [settings, setSettings] = useState([]);
  const defaultLanguage =
    settings.language && settings.language.length > 0
      ? settings.language[0].value
      : "fr";
  const [generalSettings, setGeneralSettings] = useState([]);

  useEffect(() => {
    const fetchGeneralSettings = async () => {
      try {
        const settingsRef = doc(db, "general/settings");
        const docSnap = await getDoc(settingsRef);
        if (docSnap.exists()) {
          setGeneralSettings(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching general settings:", error);
      }
    };
    fetchGeneralSettings();
  }, []);

  useEffect(() => {
    if (!currentUser) return;

    // Référence du document de la commande dans Firestore
    const orderRef = doc(db, `restaurants/${restaurantId}/orders`, orderId);

    // Écoute les mises à jour en temps réel
    const unsubscribe = onSnapshot(
      orderRef,
      (orderSnap) => {
        if (orderSnap.exists()) {
          const order = orderSnap.data();

          // Vérifie si l'utilisateur a accès à cette commande
          if (order.customerDetails.userId !== currentUser.uid) {
            console.error(
              "Access denied: You do not have permission to view this order."
            );
            navigate("/account/orders");
          } else {
            setOrderData(order);
          }
        } else {
          console.log("No such order!");
        }

        setLoading(false);
      },
      (error) => {
        console.error("Error fetching order:", error);
        setLoading(false);
      }
    );

    // Nettoyage de l'écouteur lorsque le composant est démonté
    return () => unsubscribe();
  }, [restaurantId, orderId, currentUser, navigate]);

  // Assurez-vous que fetchSettings ne s'exécute qu'une fois que orderData est chargé
  useEffect(() => {
    const fetchSettings = async () => {
      if (orderData?.restaurantId) {
        // Assurez-vous que orderData est chargé avant d'accéder à restaurantId
        const settingsRef = doc(
          db,
          `restaurants/${orderData.restaurantId}/settings`,
          "config"
        );
        try {
          const docSnap = await getDoc(settingsRef);
          if (docSnap.exists()) {
            let data = docSnap.data();

            if (
              data.shopSettings &&
              data.shopSettings.paymentMethods &&
              !(data.shopSettings.paymentMethods instanceof Array)
            ) {
              data.shopSettings.paymentMethods = Object.entries(
                data.shopSettings.paymentMethods
              ).map(([key, value]) => ({
                name: key,
                ...value,
              }));
            }

            setSettings(data);
          }
        } catch (error) {
          console.error("Error fetching settings:", error);
        }
      }
    };
    fetchSettings();
  }, [orderData]); // Ajoutez orderData comme dépendance

  const openInvoice = async (order) => {
    try {
      const blob = await pdf(
        <OrderInvoice order={order} generalSettings={generalSettings} />
      ).toBlob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Invoice-${orderData.orderId}.pdf`;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error generating invoice:", error);
    }
  };



  if (loading) {
    return <Spinner />;
  }

  if (!orderData) {
    return <Text>No order found</Text>;
  }




  return (
    <Flex direction={"column"} gap={0} pb={16}>
      <Flex
        gap={4}
        alignItems={{ desktop: "center", mobile: "flex-start" }}
        pb={8}
        borderBottom={"1px solid var(--borders)"}
        justifyContent={{ desktop: "space-between", mobile: "flex-start" }}
        direction={{ desktop: "row", mobile: "column" }}
        >
        
        <Flex direction={"column"} gap={1}>
        <Heading>Order #{orderId}</Heading>
        <Text>{orderData.cartItems.length} item(s) for {orderData.total}€ to be {orderData.deliveryMode === "pickup" ? "picked up" : "delivered"} on <strong>{new Date(orderData.scheduledTime.seconds * 1000).toLocaleString(undefined, {
                    timeStyle: 'short', // This will display time without seconds
                    dateStyle: 'short', // This will display the date in a short format (optional)
                })}</strong>{orderData.deliveryMode === "delivery" && ` at ${orderData.customerDetails.address} ${orderData.customerDetails.postalCode} ${orderData.customerDetails.city}`}
        </Text>
        </Flex>

        <Flex gap={2} alignItems={"center"}>
        <Flex alignItems={"center"} gap={1} px={3} py={1.5} bg={"var(--grey100)"} w={"fit-content"} fontWeight={600} borderRadius={8} textTransform={"Capitalize"}>{orderData.deliveryMode}</Flex>
        <Flex
            bg={
              orderData.paymentStatus === "paid"
                ? "var(--success50)"
                : "var(--error50)"
            }
            color={
              orderData.paymentStatus === "paid"
                ? "var(--success700)"
                : "var(--error700)"
            }
            px={6}
            py={1.5}
            borderRadius={8}
            fontWeight={700}
            textTransform={"capitalize"}>
            {orderData.paymentStatus === "paid" ? "Paid" : "Unpaid"}
          </Flex>
          {orderData.status === "completed" && (
            <Button
              gap={0}
              leftIcon={<FileText size={16} />}
              size="md"
              variant="outline"
              onClick={() => openInvoice(orderData)}>
              Download invoice
            </Button>
          )}
        </Flex>
      </Flex>
      
      
      <Flex
      
        direction={{ desktop: "row", mobile: "column" }}
        gap={{ desktop: 16, mobile: 8 }}
        justifyContent={"flex-start"}>
        
        
        <OrderSteps orderData={orderData} />

        <Flex direction={"column"} gap={8} pt={orderData.paymentInfo ? 8 : 0} flex={1} >
    


    {orderData.paymentInfo &&
    <Flex gap={4}>
        <Box style={{width: 58, height: 40, position: 'relative', background: 'white', borderRadius: 6, border: '1px var(--borders) solid'}}>
            <Image style={{width: 40, height: 13, left: 8, top: 13.50, position: 'absolute'}} src="https://via.placeholder.com/40x13" />
        </Box>
                <Flex direction={'column'}>
                    <Text style={{color: '#414651', fontSize: 14, fontWeight: '500',  wordWrap: 'break-word'}}>Paid with Visa ending in 1234</Text>
                    <Text style={{fontSize: 13, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Expiry 06/2025</Text>
                </Flex>
    </Flex>
    }

          
      
          <Accordion allowToggle defaultIndex={[0]} flex={1}>
            <AccordionItem index={0 } borderTop={{ desktop: orderData.paymentInfo ? "1px solid var(--borders)" : "none", mobile: "1px solid var(--borders)" }}>
              <h5>
                <AccordionButton
                  color={"var(--grey700)"}
                  _hover={{ bg: "transparent" }}>
                  <Flex flex="1" textAlign="left" py={2}>
                    Restaurant details
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
              </h5>
              <AccordionPanel p={0} pb={8}>
                <Flex direction={{ desktop: "row", mobile: "column" }} gap={2} justifyContent={"space-between"}>
   
                <Flex direction={"column"} gap={1}>
                  <Link
                    to={`/${orderData.restaurantId}/shop`}
                    style={{
                      fontSize: "1.1em",
                      color: "var(--brand)",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      textDecoration: "underline",
                    }}>
                    {orderData.restaurantId}
                  </Link>
                  <Text>{orderData?.restaurantDetails?.address}</Text>
                  <Text>
                    {settings?.shopSettings?.contact?.shopManagerEmail}
                  </Text>
          
                </Flex>
                <Flex gap={1}>
                  {orderData.deliveryMode === "pickup" &&
                    <Tooltip label="Get directions on Google Maps">
                    <Button
                    as="a"
                    href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(orderData.restaurantDetails.address)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="outline">
                    Get directions
                  </Button>
                  </Tooltip>
                  }
                  <Tooltip label="Call restaurant"><IconButton as={Link} to={`tel:${settings?.shopSettings?.contact?.phoneNumber}`} variant={"outline"} icon={<Phone size={20} />} /></Tooltip>
                </Flex>
                </Flex>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem index={1}>
              <h5>
                <AccordionButton
                  color={"var(--grey700)"}
                  _hover={{ bg: "transparent" }}>
                  <Flex flex="1" textAlign="left" py={2}>
                    Order details
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
              </h5>
              <AccordionPanel p={0}>
                <List className={styles.cartItems}>
                  {orderData.cartItems.length > 0 &&
                    orderData.cartItems.map((item, index) => (
                      <ListItem key={index} className={styles.cartItem} alignItems={"center"}>
                        <div className={styles.itemDetails}>
                          <div className={styles.cartItemTitle}>
                            <div className={styles.itemName}>
                              {item.quantity} x{" "}
                              {item[`name_${defaultLanguage}`]}
                              {item.variation && (
                                <span> - {item.variation.name}</span>
                              )}
                            </div>

                            <div>
                              {(
                                item.quantity *
                                parseFloat(item.price.replace(",", "."))
                              )
                                .toFixed(2)
                                .replace(".", ",")}
                              €
                            </div>
                          </div>

                          {(item.extras?.length > 0 || item.note) && (
                            <Flex
                              className={styles.cartItemInfo}
                              direction={"column"}>
                              {item.extras && item.extras.length > 0 && (
                                <div className={styles.extrasList}>
                                  <h6>Extras</h6>
                                  {item.extras.map((extra, extraIndex) => (
                                    <span
                                      key={extraIndex}
                                      className={styles.extra}>
                                      {extra.name}
                                      {extraIndex < item.extras.length - 1 && (
                                        <span className={styles.separator}>
                                          {" "}
                                          -{" "}
                                        </span>
                                      )}
                                    </span>
                                  ))}
                                </div>
                              )}

                              {item.note && (
                                <div className={styles.extrasList}>
                                  <h6>Note</h6>
                                  <span>{item.note}</span>
                                </div>
                              )}
                            </Flex>
                          )}
                        </div>
                      </ListItem>
                    ))}
                </List>
                <ul className={styles.cartFooter}>
                  <li>
                    <label>Subtotal</label>
                    <div>{orderData?.subTotal.replace(".", ",")}€</div>
                  </li>
                  {orderData.discount > 0 && (
                    <li>
                      <label>Discount</label>
                      <div className={styles.qty}>
                        <div>-{orderData?.discount}€</div>
                      </div>
                    </li>
                  )}

                  {orderData.tip > 0 && (
                    <li>
                      <label>Tip </label>
                      <div className={styles.qty}>
                        <div>{orderData?.tip}€</div>
                      </div>
                    </li>
                  )}

                  {orderData.deliveryMode === "delivery" &&
                    orderData.deliveryFee > 0 && (
                      <li>
                        <label>Delivery fee</label>
                        <div>
                          {orderData.deliveryFee.toFixed(2).replace(".", ",")}€
                        </div>
                      </li>
                    )}

                  {orderData.serviceFeeTTC > 0 && (
                    <li>
                      <label>Service fee</label>
                      <div>
                        {orderData.serviceFeeTTC.toFixed(2).replace(".", ",")}€
                      </div>
                    </li>
                  )}
                  <li>
                      <label><strong>Total</strong></label>
                      <div>
                      <strong>{orderData.total}€</strong>
                      </div>
                    </li>
                  
                </ul>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          </Flex>
        </Flex>
    </Flex>
  );
};

export default OrderDetails;
