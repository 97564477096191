import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, Timestamp } from "firebase/firestore";
import { db } from '../firebaseConfig';
import { useShopContext } from '../shop/context/ShopContext';
import { useAuth } from '../shop/context/AuthContext';

const useCoupons = (restaurantId) => {
  
  const [userCoupons, setUserCoupons] = useState([]);
  const { calculateSubtotal, couponCode, setCouponCode, discountAmount, setDiscountAmount, setTotalAfterDiscountValue, formatPrice, isCouponValid, setIsCouponValid, couponDetails, setCouponDetails  } = useShopContext();
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchCoupons = async () => {
      if (!currentUser) return;
  
      const couponsRef = collection(db, `restaurants/${restaurantId}/coupons`);
  
      // Query for "All customers"
      const allCustomersQuery = query(
        couponsRef,
        where('application', '==', 'All customers')
      );
      const allCustomersSnapshot = await getDocs(allCustomersQuery);
  
      // Query for user-specific coupons by email
      const userSpecificQuery = query(
        couponsRef,
        where('application', 'array-contains', currentUser.email)
      );
      const userSpecificSnapshot = await getDocs(userSpecificQuery);
  
      // Combine results from both queries
      let fetchedCoupons = [
        ...allCustomersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })),
        ...userSpecificSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
      ];

      // Filtrer les coupons qui ont atteint leur limite d'utilisation ou qui sont expirés
      const validCoupons = [];
      for (const coupon of fetchedCoupons) {
        const usageCount = await checkCouponUsage(coupon.id, currentUser.uid);
        
        if (usageCount < coupon.maxUses) {
          // Optionnel: Si vous avez un champ expirationDate
          if (coupon.expirationDate) {
            const expirationTimestamp = coupon.expirationDate.toDate();
            if (expirationTimestamp > new Date()) {
              validCoupons.push(coupon);
            }
          } else {
            validCoupons.push(coupon);
          }
        }
      }
  
      setUserCoupons(validCoupons);
    };
  
    fetchCoupons();
  }, [restaurantId, currentUser]);

  const handleApplyCoupon = async () => {
    console.log("Applying coupon:", couponCode);

    const foundCoupon = userCoupons.find(coupon => coupon.name === couponCode);
  
    if (!foundCoupon) {
        console.log("Coupon not found:", couponCode);
        setIsCouponValid(false);
        console.log("isCouponValid set to false");
        setDiscountAmount(0);
        setCouponDetails(null);  // Réinitialiser couponDetails
        return;
    }
  
    const usageCount = await checkCouponUsage(foundCoupon.id, currentUser.uid);
  
    if (usageCount >= foundCoupon.maxUses) {
        setIsCouponValid(false);
        console.log("isCouponValid set to false due to max uses");
        setDiscountAmount(0);
        setCouponDetails(null);  // Réinitialiser couponDetails
        return;
    }
  
    const discountValue = parseFloat(foundCoupon.couponValue.split(' ')[0]);
    const discountType = foundCoupon.couponValue.split(' ')[1];
    setIsCouponValid(true);
    console.log("isCouponValid set to true (coupon is valid)");

    let discountAmountValue = 0;
    const total = parseFloat(calculateSubtotal().replace(",", "."));
    
    if (discountType === '%') {
      discountAmountValue = total * (discountValue / 100);
    } else {
      discountAmountValue = discountValue;
    }
  
    setDiscountAmount(discountAmountValue);
    setTotalAfterDiscountValue(formatPrice(total - discountAmountValue));
  
    // Stocke les détails du coupon validé
    setCouponDetails(foundCoupon);
  
    console.log("Coupon applied successfully with discount:", discountAmountValue);
};

  const checkCouponUsage = async (couponId, userId) => {
    const usageRef = collection(db, `restaurants/${restaurantId}/couponsUsageLogs`);
    const q = query(
      usageRef,
      where('userId', '==', userId),
      where('couponId', '==', couponId)
    );
    const usageSnapshot = await getDocs(q);
  
    return usageSnapshot.size;
  };

  const logCouponUsage = async (couponId, userId, orderId) => {
    
    console.log(`logCouponUsage called with couponId: ${couponId}, userId: ${userId}, orderId: ${orderId}`);
    
    if (!orderId || typeof orderId !== "string" || orderId.trim() === "") {
        console.error("Invalid orderId passed to logCouponUsage:", orderId);
        return;
    }

    const usageRef = collection(db, `restaurants/${restaurantId}/couponsUsageLogs`);
    const newLog = {
        couponId,
        userId,
        orderId: orderId,
        usedAt: Timestamp.now()  // Use Firestore Timestamp to store the current time
    };
    await addDoc(usageRef, newLog);
};

  return {
    couponCode,
    setCouponCode,
    discountAmount,
    isCouponValid,
    handleApplyCoupon,
    userCoupons,
    checkCouponUsage,
    logCouponUsage
  };
};

export default useCoupons;
