import { Flex, Text } from "@chakra-ui/react";
import { Check, X } from "lucide-react";
  
  function OrderSteps({ orderData }) {
  
    const step = {
    className: "orderStep",
    pb: 12,
    direction: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 4,
  };

  const circle = {
    className: "circle", 
    bg: "white",
    w: "42px",
    minW: "42px",
    h: "42px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    fontWeight: "600",
    border: "2px solid",
    zIndex: 1,
  };
  const progressBarBg = {
    content: '',
    backgroundColor: 'var(--borders)',
    width: '7px',
    position: 'absolute',  
    top: '21px',
    bottom: '0',
    left: '21px',
    transform: 'translateX(-50%)',
    zIndex: '-1'
  };
  const progressBar = {
    content: '',
    backgroundColor: 'var(--success700)',
    width: '7px',
    position: 'absolute',  
    top: '21px',
    transition: 'all 0.74s ease',
    left: '21px',
    transform: 'translateX(-50%)',
    zIndex: '0',
  };
  const text = {
    transition: 'all 0.74s ease',
    lineHeight: 1,
    gap: 2,
    maxW: "420px",
  };

  return (
    
    <Flex
      w={{ desktop: "50%", mobile: "100%" }}
      direction="column"
        className="orderSteps"
        pt={8}
        gap={0}
        justifyContent="flex-start">
        <Flex {...step}>
        <Flex {...progressBarBg}></Flex>
        <Flex {...progressBar} bottom={orderData.status != "pending" ? "0" : "100%"}></Flex>
          <Flex {...circle} color={"var(--success700)"} opacity={1}>
            <Check />
          </Flex>
          <Flex {...text} direction={"column"} opacity={1}>
          <Text fontWeight={orderData.status === "pending" ? "600" : "500"} fontSize={"16px"} color={"var(--heading)"}>Order placed</Text>
          <Text fontWeight="400" color={"var(--text)"} lineHeight={"1.3"}>We received your order on {new Date(orderData.createdAt.seconds * 1000).toLocaleString(undefined, {
                    timeStyle: 'short', // This will display time without seconds
                    dateStyle: 'short', // This will display the date in a short format (optional)
                })}. Hang on, someone will see it soon. </Text>
          </Flex>
        </Flex>

        {orderData.status === "cancelled" &&
        <Flex {...step}>
        <Flex {...progressBarBg}></Flex>
          <Flex {...circle} color={"var(--error700)"} opacity={1}>
            <X />
          </Flex>
          <Flex {...text} direction={"column"} opacity={1}>
          <Text fontWeight={orderData.status === "cancelled" ? "600" : "500"} fontSize={"16px"} color={"var(--error700)"}>Order cancelled</Text>
          <Text fontWeight="400" color={"var(--error700)"} lineHeight={"1.3"}>Sorry, your order has been rejected. {orderData.cancellationNote ? `Reason : ${orderData.cancellationNote}` : ""}</Text>
          </Flex>
        </Flex>
        }

        <Flex {...step}>
        <Flex {...progressBarBg}></Flex>
        <Flex {...progressBar} bottom={(orderData.status === "sent" || orderData.status === "completed") ? "0" : "100%"}></Flex>  
          <Flex {...circle} color={(orderData.status === "completed" || orderData.status === "sent" || orderData.status === "confirmed") ? "var(--success700)" : "var(--borders)"}>
            {(orderData.status === "completed" || orderData.status === "sent" || orderData.status === "confirmed") ? <Check /> : "2"}
          </Flex>
          <Flex {...text} direction={"column"} opacity={(orderData.status === "completed" || orderData.status === "sent" || orderData.status === "confirmed") ? 1 : 0.5}>
          <Text fontWeight="500" fontSize={"16px"} color={"var(--heading)"}>Order confirmed</Text>
          <Text fontWeight="400" color={"var(--text)"} lineHeight={"1.3"}>Sit back and relax, we are preparing your order.</Text>
          </Flex>
        </Flex>

        {orderData.deliveryMode === "delivery" && (
          <Flex {...step}>
            <Flex {...progressBarBg}></Flex>
            <Flex {...progressBar} bottom={(orderData.status === "completed") ? "0" : "100%"}></Flex>  
            <Flex {...circle}  color={(orderData.status === "sent" || orderData.status === "completed") ? "var(--success700)" : "var(--borders)"}>
                {(orderData.status === "sent" || orderData.status === "completed") ? <Check /> : "3"}
            </Flex>
            <Flex {...text} direction={"column"} opacity={(orderData.status === "sent" || orderData.status === "completed") ? 1 : 0.5}>
          <Text fontWeight="500" fontSize={"16px"} color={"var(--heading)"}>Order sent</Text>
          <Text fontWeight="400" color={"var(--text)"} lineHeight={"1.3"}>Food is on its way! Your order has been sent for delivery.</Text>
          </Flex>
          </Flex>
        )}

        <Flex {...step} pb={0}>
          <Flex {...circle} color={orderData.status === "completed" ? "var(--success700)" : "var(--borders)"}>
          {orderData.status === "completed" ? <Check /> : (orderData.deliveryMode === "pickup" ? 3 : 4)}
          </Flex>
          <Flex {...text} direction={"column"} opacity={orderData.status === "completed" ? 1 : 0.5} >
            <Text fontWeight="500" fontSize={"16px"} color={"var(--heading)"}>Order {orderData.deliveryMode === "pickup" ? "picked up" : "delivered"}</Text>
            <Text fontWeight="400" color={"var(--text)"} lineHeight={"1.3"}>Your order should have been {orderData.deliveryMode === "pickup" ? "picked up" : "delivered"} by now. Bon appétit!</Text>
          </Flex>
        </Flex>
      </Flex>

      
    
  );
}

export default OrderSteps;
