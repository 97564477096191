// ShopContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import {
  doc,
  getDoc,
  updateDoc,
  setDoc,
  query,
  collection,
  where,
  getDocs,
  orderBy,
  Timestamp
} from "firebase/firestore";
import styles from "../shop.module.css";
import { Helmet } from "react-helmet";
import ShopHeader from "../components/ShopHeader";
import { Flex } from "@chakra-ui/react";
import { DateTime } from 'luxon';
import { useAuth } from "./AuthContext";


const ShopContext = createContext();

export const useShopContext = () => useContext(ShopContext);

export const ShopProvider = ({ children, restaurantId }) => {
  
  
  
  useEffect(() => {
    if (restaurantId) {
      sessionStorage.setItem("restaurantId", restaurantId);
    }
  }, [restaurantId]);
  
  
  const [generalSettings, setGeneralSettings] = useState({ 
    vat: '',
  });

  const VAT_RATE = parseFloat(generalSettings.vat) / 100;
  
  
  const [settings, setSettings] = useState({
    language: [{ value: "", label: "" }],
    secondaryLanguages: [],
    brandColor: "",
    backgroundColor: "",
    textColor: "",
    logo: {
      menu: "",
      shop: "",
    },
    backgroundImage: "",
    menuUrl: "",
    shopSettings: {
      takeAway: {
        activation: "false",
        minimumOrderAmount: 0,
      },
      delivery: {
        activation: "false",
        minimumOrderAmount: 0,
        zone: [],
        deliveryFee: "",
      },
      contact: {
        shopManagerEmail: '',
        phoneNumber:'',
        address: '',
        companyName: '',
        vat:'',
        rcs: '',
      },
      paymentMethods: {
        till: {
          id: "till",
          activation: false,
          label: "Pay upon pickup",
          order: 0
        },
        online: {
          id: "online",
          label: "Pay online by credit card",
          activation: false,
          PublicLive: "",
          SecretLive: "",
          PublicTest: "",
          SecretTest: "",
          order: 1
        },
        closingDays: [],
      },
      fees: {
        commissionFee: "",
      },
    },
  });

  const serviceFeeTTC = parseFloat(settings.shopSettings.fees.serviceFee) * (1 + generalSettings.vat / 100);
  const deliveryFee = parseFloat(settings.shopSettings.delivery.deliveryFee);

  const [stripeKeys, setStripeKeys] = useState({
    PublicLive: "",
    SecretLive: "",
    PublicTest: "",
    SecretTest: "",
  });
  

  const defaultLanguage = settings.language.length > 0 ? settings.language[0].value : "fr";
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const [mainCategories, setMainCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [menuItems, setMenuItems] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);
  const [extrasGroups, setExtrasGroups] = useState([]);

  const [frontendTags, setFrontendTags] = useState([]);
  let initialPrice = 0;
  let initialVariation = null;
  const [quantity, setQuantity] = useState(1);
  const [subTotal, setSubTotal] = useState(initialPrice);
  const [selectedVariation, setSelectedVariation] = useState(initialVariation);
  const [selectedExtras, setSelectedExtras] = useState([]);
  const [deliveryMode, setDeliveryMode] = useState("pickup");
  const [nextAvailableSlot, setNextAvailableSlot] = useState("");
  const [scheduledSlot, setScheduledSlot] = useState("");
  const [scheduledSlotDate, setScheduledSlotDate] = useState("");
  const [isLoading, setIsLoading ] = useState(true);
  const [couponCode, setCouponCode] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [couponDetails, setCouponDetails] = useState(null);
  const [isPlanningTime, setIsPlanningTime] = useState(false);

  
  const [tip, setTip] = useState(() => {
    const savedTip = localStorage.getItem("tip");
    return savedTip ? parseFloat(savedTip) : 0;
  });
  
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const validPostalCodes = settings.shopSettings.delivery.zone;
  const [isDeliveryZoneValid, setIsDeliveryZoneValid] = useState(true);

  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const { currentUser } = useAuth();


  const onPlaceSelected = (place) => {
    if (!place || !place.address_components) {
      console.error("No address components found.");
      setIsAddressSelected(false);
      return;
    }
  
    const addressObject = place.address_components.reduce(
      (acc, component) => {
        const componentType = component.types[0];
        switch (componentType) {
          case "street_number": {
            acc.address = `${component.long_name} ${acc.address}`;
            break;
          }
          case "route": {
            acc.address = `${acc.address} ${component.long_name}`.trim();
            break;
          }
          case "locality":
          case "political": {
            acc.city = component.long_name;
            break;
          }
          case "postal_code": {
            acc.postalCode = component.long_name;
            break;
          }
          default: {
            break;
          }
        }
        return acc;
      },
      { address: "", postalCode: "", city: "" }
    );
  
    addressObject.address = addressObject.address.trim(); // Make sure there are no leading or trailing spaces
  
    const postalCodeIsValid = validPostalCodes.includes(addressObject.postalCode);
    setIsDeliveryZoneValid(postalCodeIsValid);
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      customerDetails: {
        ...prevFormData.customerDetails,
        address: addressObject.address,
        postalCode: addressObject.postalCode,
        city: addressObject.city,
      },
    }));
  
    // Update address selected status based on whether a valid address was found
    const isAddressComplete = addressObject.address.length > 0 && addressObject.city && addressObject.postalCode;
    setIsAddressSelected(isAddressComplete && postalCodeIsValid);
  };
  

  useEffect(() => {
    localStorage.setItem("tip", tip.toString());
  }, [tip]);

  const allLanguages = [
    defaultLanguage,
    ...settings.secondaryLanguages.map((lang) => lang.value),
  ].filter(Boolean);

  const cartId = localStorage.getItem("cartId");
  const [cartItems, setCartItems] = useState([]);
  const [cartUpdated, setCartUpdated] = useState(false);


  useEffect(() => {
    const fetchMainCategories = async () => {
      if (restaurantId) {
        const querySnapshot = await getDocs(
          collection(db, `restaurants/${restaurantId}/menu`)
        );
        const fetchedCategories = querySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter(
            (category) =>
              !category.id.endsWith("-categories") && category.published
          )
          .sort((a, b) => a.order - b.order);

        const mainCategoriesObject = {};
        fetchedCategories.forEach((category) => {
          mainCategoriesObject[category.id] = category;
        });

        setMainCategories(mainCategoriesObject);
      }
    };
    fetchMainCategories();
  }, [restaurantId]);

  useEffect(() => {
    const fetchSubCategories = async () => {
      if (!restaurantId) return;

      let allSubCategories = [];
      const mainCategoryIds = Object.keys(mainCategories);

      for (const categoryId of mainCategoryIds) {
        const path = `restaurants/${restaurantId}/menu/${categoryId}-categories/categories`;
        const categoriesQuery = query(collection(db, path), orderBy("order"));
        const categoriesSnapshot = await getDocs(categoriesQuery);
        const fetchedCategories = categoriesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          mainCategoryId: categoryId,
        }));

        allSubCategories = allSubCategories.concat(fetchedCategories);
      }

      setSubCategories(allSubCategories);
    };

    if (Object.keys(mainCategories).length > 0) {
      fetchSubCategories();
    }
  }, [restaurantId, mainCategories]);

  useEffect(() => {
    const fetchAllMenuItems = async () => {
      if (!restaurantId) return;

      let allMenuItems = [];
      for (const categoryId of Object.keys(mainCategories)) {
        const path = `restaurants/${restaurantId}/menu/${categoryId}/items`;
        const menuQuery = collection(db, path);
        const menuSnapshot = await getDocs(menuQuery);
        const fetchedMenuData = menuSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          mainCategoryId: categoryId,
        }));

        allMenuItems = allMenuItems.concat(fetchedMenuData);
      }

      // Triez si nécessaire
      allMenuItems.sort((a, b) => a.order - b.order);

      setIsLoading(false);
      setMenuItems(allMenuItems);
    };

    if (Object.keys(mainCategories).length > 0) {
      fetchAllMenuItems();
    }
  }, [restaurantId, mainCategories]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        if (!restaurantId || Object.keys(mainCategories).length === 0) {
          return;
        }

        let allTags = [];
        for (const categoryId of Object.keys(mainCategories)) {
          const path = `restaurants/${restaurantId}/menu/${categoryId}/tags`;
          const querySnapshot = await getDocs(query(collection(db, path)));
          const tagsData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            mainCategoryId: categoryId,
          }));

          allTags = allTags.concat(tagsData);
        }

        setFrontendTags(allTags);
      } catch (error) {
        console.error("Erreur lors de la récupération des tags :", error);
      }
    };

    fetchTags();
  }, [restaurantId, mainCategories]); // Dépendances : restaurantId et mainCategories

  useEffect(() => {
    const fetchExtrasGroups = async () => {
      try {
        if (!restaurantId || Object.keys(mainCategories).length === 0) {
          return;
        }

        let allExtrasGroups = [];
        for (const categoryId of Object.keys(mainCategories)) {
          const path = `restaurants/${restaurantId}/menu/${categoryId}/extras`;
          const querySnapshot = await getDocs(collection(db, path));
          const extrasGroupsData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            mainCategoryId: categoryId, // Ajoutez cette ligne si vous avez besoin de savoir à quelle catégorie principale chaque groupe d'extras appartient
          }));

          allExtrasGroups = allExtrasGroups.concat(extrasGroupsData);
        }

        setExtrasGroups(allExtrasGroups);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des groupes d'extras :",
          error
        );
      }
    };

    fetchExtrasGroups();
  }, [restaurantId, mainCategories]);

  useEffect(() => {
    const fetchGeneralSettings = async () => {
      const settingsRef = doc(db, "general/settings");
      const docSnap = await getDoc(settingsRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setGeneralSettings(data);
      } else {
        console.log("No such document!");
      }
    };
    fetchGeneralSettings();
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      if (restaurantId) {
        const settingsRef = doc(
          db, `restaurants/${restaurantId}/settings`, "config" );
        try {
          const docSnap = await getDoc(settingsRef);
          if (docSnap.exists()) {
            let data = docSnap.data();

            if (
              data.shopSettings &&
              data.shopSettings.paymentMethods &&
              !(data.shopSettings.paymentMethods instanceof Array)
            ) {
              data.shopSettings.paymentMethods = Object.entries(
                data.shopSettings.paymentMethods
              ).map(([key, value]) => ({
                name: key,
                ...value,
              }));
            }

            setSettings(data);
            if (data.language && data.language.length > 0) {
              setSelectedLanguage(data.language[0].value);
            }
          }
        } catch (error) {
          console.error("Error fetching settings:", error);
        }
      }
    };
    fetchSettings();
  }, [restaurantId]);

  useEffect(() => {
    const fetchCartItems = async () => {
      const cartId = localStorage.getItem("cartId");
      if (!cartId) return;
      const q = query(
        collection(db, `restaurants/${restaurantId}/cart`),
        where("cartId", "==", cartId)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const items = querySnapshot.docs.flatMap(
          (doc) => doc.data().items || []
        );
        setCartItems(items);
      }
    };
    fetchCartItems();
  }, [restaurantId, cartId, cartUpdated]);

  
  const handleLanguageChange = (newLanguageValue) => {
    setSelectedLanguage(newLanguageValue);
  };

  const getOrCreateCartId = () => {
    let cartId = localStorage.getItem("cartId");
    if (!cartId) {
      cartId = Math.random().toString(36).substring(2, 15);
      localStorage.setItem("cartId", cartId);
    }
    return cartId;
  };

  

  const addItemToCart = async (cartItem, allLanguages) => {
    const cartId = getOrCreateCartId();
    const cartRef = doc(db, `restaurants/${restaurantId}/cart`, cartId);
    const cartSnap = await getDoc(cartRef);

    if (cartSnap.exists()) {
      let cartData = cartSnap.data();
      const itemIndex = cartData.items.findIndex(
        (i) =>
          i.itemId === cartItem.itemId &&
          ((i.variation &&
            cartItem.variation &&
            i.variation.name === cartItem.variation.name) ||
            (!i.variation && !cartItem.variation)) && 
          i.note === cartItem.note && 
          compareExtras(i.extras, cartItem.extras)
      );

      if (itemIndex > -1) {
        cartData.items[itemIndex].quantity += cartItem.quantity;
      } else {
        cartData.items.push(cartItem);
      }
      await updateDoc(cartRef, { items: cartData.items });
    } else {
      await setDoc(cartRef, {
        items: [cartItem],
        cartId: cartId,
      });
    }
    setCartUpdated((prev) => !prev);
  };

  function compareExtras(extras1 = [], extras2 = []) {
    if (extras1.length !== extras2.length) return false;
    const sortedExtras1 = [...extras1].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    const sortedExtras2 = [...extras2].sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    return sortedExtras1.every(
      (extra, index) => extra.name === sortedExtras2[index].name
    );
  }

  const handleDeliveryModeChange = (event) => {
    setDeliveryMode(event.target.value);
    setIsPlanningTime(false);
    if (event.target.value === "pickup") {
      setFormData({ ...formData });
    }
  };

  const updateItemQuantity = async (itemId, variationName, quantity, note = null, extras = []) => {
    const cartId = localStorage.getItem("cartId");
    if (!cartId) return;
  
    const cartRef = doc(db, `restaurants/${restaurantId}/cart`, cartId);
    const cartSnap = await getDoc(cartRef);
  
    if (cartSnap.exists()) {
      let cartData = cartSnap.data();
      const itemIndex = cartData.items.findIndex((i) => {
        const isItemIdMatch = i.itemId === itemId;
        const isVariationMatch = variationName ? i.variation && i.variation.name === variationName : !i.variation;
        const isNoteMatch = i.note === note || (i.note === undefined && note === null);
        const isExtrasMatch = extras.length === (i.extras ? i.extras.length : 0) && 
                              extras.every(extra => i.extras.some(e => e.name === extra.name));
  
        return isItemIdMatch && isVariationMatch && isNoteMatch && isExtrasMatch;
      });
  
      if (itemIndex > -1 && quantity > 0) {
        cartData.items[itemIndex].quantity = quantity;
      } else if (itemIndex > -1 && quantity <= 0) {
        cartData.items.splice(itemIndex, 1);
      }
  
      await updateDoc(cartRef, cartData);
      setCartUpdated((prev) => !prev);
    }
  };
  

  const calculateSubTotal = () => {
    let basePrice = 0;

    if (selectedItem.type === "variable" && selectedVariation) {
      basePrice = parseFloat(selectedVariation.price.replace(",", "."));
    } else if (selectedItem.type !== "variable" && selectedItem.price) {
      basePrice = parseFloat(selectedItem.price.replace(",", "."));
    }

    const extrasTotal = selectedExtras.reduce((total, extra) => {
      const extraPrice = parseFloat(extra.price);
      return total + extraPrice;
    }, 0);

    const total = (basePrice + extrasTotal) * quantity;

    return formatPrice(total);
  };

  const calculateSubtotal = () => {
    const total = cartItems.reduce((acc, item) => {
      const itemTotal =
        parseFloat(item.price.replace(",", ".")) * item.quantity;
      return acc + itemTotal;
    }, 0);
    return total.toFixed(2);
  };



  const totalWithTip = () => {
    const total = calculateSubtotal();
    const totalNum = parseFloat(total.replace(",", ".")) || 0;
    const deliveryFeeToAdd = deliveryMode === 'delivery' ? deliveryFee : 0;
    const totalWithTipValue = (totalNum + tip + serviceFeeTTC + deliveryFeeToAdd).toFixed(2);
    return totalWithTipValue.replace(".", ",");
  };
  
  const [totalAfterDiscountValue, setTotalAfterDiscountValue] = useState(totalWithTip());
  

  

  const clearCart = async () => {
    const cartId = localStorage.getItem('cartId');
    if (!cartId) {
      console.error("No cartId found in localStorage.");
      return;
    }
    const cartRef = doc(db, `restaurants/${restaurantId}/cart`, cartId);
    try {
      await updateDoc(cartRef, { items: [] });
      localStorage.removeItem('cartId'); 
      localStorage.removeItem('tip'); 
      setTip(0);
      setCartItems([]);
    } catch (error) {
      console.error("Error clearing the cart:", error);
    }
  };
  const [formData, setFormData] = useState({
    customerDetails: {
      firstName: currentUser?.firstName || "",
      lastName: currentUser?.lastName || "",
      email: currentUser?.email || "",
      phone: currentUser?.phone || "",
      address: currentUser?.address || "",
      postalCode: currentUser?.postalCode || "",
      city: currentUser?.city || "",
    },
  });

  const submitOrder = async (orderData, paymentStatus = "unpaid") => {
    const deliveryTime = Number(settings.shopSettings.delivery.deliveryTime) || 0;
    const preparationTime = settings.shopSettings.general.preparationTime;

    if (!orderData.scheduledTime) {
        console.error('Error: scheduledTime is required.');
        throw new Error('scheduledTime is required.');
    }

    let scheduledDateTime;
    if (orderData.scheduledTime instanceof DateTime) {
        scheduledDateTime = orderData.scheduledTime;
    } else {
        console.error('scheduledTime must be a Luxon DateTime object');
        throw new Error('scheduledTime must be a DateTime object');
    }

    const orderId = Math.floor(10000 + Math.random() * 90000).toString(); // Generating a random orderId
    const createdAt = new Date();

    let startPreparation;
    if (deliveryMode === 'delivery') {
        startPreparation = scheduledDateTime.minus({ minutes: preparationTime + deliveryTime });
    } else if (deliveryMode === 'pickup') {
        startPreparation = scheduledDateTime.minus({ minutes: preparationTime });
    }

    const logEntry = {
        action: `Order placed`,
        timestamp: createdAt.toISOString(),
        user: formData.customerDetails.firstName + ' ' + formData.customerDetails.lastName,
    };

    const orderDataComplete = {
        ...orderData,
        orderId: orderId,
        tip: tip,
        deliveryMode: deliveryMode,
        createdAt: createdAt,
        status: "pending",
        restaurantId: restaurantId,
        restaurantDetails: {
            company: settings.shopSettings.contact.companyName,
            address: settings.shopSettings.contact.address,
            vat: settings.shopSettings.contact.vat,
            logo: settings.logo.shop,
        },
        deliveryFee: deliveryMode === 'delivery' ? settings.shopSettings.delivery.deliveryFee : 0,
        serviceFee: parseFloat(settings.shopSettings.fees.serviceFee),
        serviceFeeTTC: serviceFeeTTC,
        paymentStatus: paymentStatus,
        scheduledTime: Timestamp.fromMillis(scheduledDateTime.toMillis()),
        deliveryNote: orderData.deliveryNote,
        historyLog: [logEntry],
        couponCode: couponCode || null,
        discount: discountAmount || 0,
        startPreparation: Timestamp.fromMillis(startPreparation.toMillis()),
        vatRate: VAT_RATE,
    };

    // Log the order data for debugging
    console.log("Order data:", orderDataComplete);
    console.log("orderData.paymentInfo:", orderData.paymentInfo);

    // Only add paymentInfo if it's not null
    if (orderData.paymentInfo) {
      orderDataComplete.paymentInfo = orderData.paymentInfo;
    }

    const orderRef = doc(db, `restaurants/${restaurantId}/orders`, orderId);
    try {
        await setDoc(orderRef, orderDataComplete);
        await clearCart();
        console.log(`Order placed successfully with orderId: ${orderId}`);
        return { orderId }; // Ensure orderId is returned
    } catch (error) {
        console.error("Error creating order:", error);
        throw error;
    }
};







  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      customerDetails: {
        ...prevState.customerDetails,
        [name]: value,
      },
    }));
  };




  const formatPrice = (price) => {
    const numericPrice = parseFloat(price);

    if (isNaN(numericPrice)) {
        console.error("Price is not a valid number in formatPrice", price);
        return "0,00";
    }

    return numericPrice.toFixed(2).replace(".", ",");
};

  const isFormValid = () => {
    const hasPaymentMethod = selectedPaymentMethod !== null;
    const areCommonFieldsValid =
      formData.customerDetails.firstName &&
      formData.customerDetails.lastName &&
      formData.customerDetails.email &&
      formData.customerDetails.phone;
    if (deliveryMode === "delivery") {
      return hasPaymentMethod && areCommonFieldsValid;
    }
    return hasPaymentMethod && areCommonFieldsValid;
  };

  const calculateMinimumOrderAmountDifference = () => {
    const totalValue = parseFloat(calculateSubtotal()); // Obtenir le sous-total du panier
    const minimumOrderAmountValue =
      deliveryMode === "delivery"
        ? parseFloat(settings.shopSettings.delivery.minimumOrderAmount) // Minimum pour la livraison
        : parseFloat(settings.shopSettings.takeAway.minimumOrderAmount); // Minimum pour le pickup
    
    const differenceValue = minimumOrderAmountValue - totalValue;
    return differenceValue > 0 ? differenceValue : 0; // Si la différence est positive, renvoyer la valeur, sinon renvoyer 0
  };
  

  const total = parseFloat(calculateSubtotal());
  const minimumOrderAmount = parseFloat(
    settings.shopSettings.minimumOrderAmount
  );
  const difference = minimumOrderAmount - total;

  const isCheckoutDisabledCart = () => {
    const cartIsEmpty = cartItems.length === 0; // Vérifie si le panier est vide
    const belowMinimumOrder = calculateMinimumOrderAmountDifference() > 0; // Vérifie si on est en-dessous du montant minimum
    return cartIsEmpty || belowMinimumOrder; // Désactiver si le panier est vide ou si le minimum n'est pas atteint
  };
  
  
  const isCheckoutDisabledCheckout = () => {
    const cartIsEmpty = cartItems.length === 0;
    return cartIsEmpty || calculateMinimumOrderAmountDifference() > 0 || !isFormValid();
  };
  
  function capitalizeWords(str) {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }


  const loadStripeKeys = async () => {
    if (restaurantId) {
      const docRef = doc(db, `stripeConfigs/${restaurantId}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setStripeKeys(docSnap.data());
      } else {
        console.log("Aucune configuration Stripe trouvée.");
      }
    }
  };


  const updateUserDetails = (updatedFields) => {
    setFormData((prev) => {
      return {
        ...prev,
        ...updatedFields,  // Met à jour les champs avec les nouvelles valeurs
      };
    });
  };


  

  return (
    <ShopContext.Provider
      value={{
        restaurantId,
        selectedLanguage,
        setSelectedLanguage,
        handleLanguageChange,
        defaultLanguage,
        allLanguages,
        mainCategories,
        setMainCategories,
        subCategories,
        setSubCategories,
        menuItems,
        setMenuItems,
        cartId,
        cartItems,
        setCartItems,
        getOrCreateCartId,
        addItemToCart,
        cartUpdated,
        setCartUpdated,
        updateItemQuantity,
        calculateSubTotal,
        calculateSubtotal,
        clearCart,
        styles,
        formData,
        setFormData,
        submitOrder,
        handleChange,
        ...generalSettings,
        ...settings,
        serviceFeeTTC,
        deliveryFee,
        selectedVariation,
        setSelectedVariation,
        selectedItem,
        setSelectedItem,
        selectedExtras,
        setSelectedExtras,
        formatPrice,
        subTotal,
        setSubTotal,
        initialPrice,
        initialVariation,
        quantity,
        setQuantity,
        tip,
        setTip,
        totalWithTip,
        totalAfterDiscountValue,
        setTotalAfterDiscountValue,
        frontendTags,
        setFrontendTags,
        extrasGroups,
        setExtrasGroups,
        deliveryMode,
        setDeliveryMode,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
        total,
        minimumOrderAmount,
        difference,
        isCheckoutDisabledCart,
        isCheckoutDisabledCheckout,
        isFormValid,
        calculateMinimumOrderAmountDifference,
        handleDeliveryModeChange,
        validPostalCodes,
        isDeliveryZoneValid,
        setIsDeliveryZoneValid,
        stripeKeys, setStripeKeys,
        loadStripeKeys,
        onPlaceSelected,
        nextAvailableSlot, setNextAvailableSlot,
        scheduledSlot, setScheduledSlot,
        scheduledSlotDate, setScheduledSlotDate,
        isLoading,
        isAddressSelected, setIsAddressSelected,
        couponCode, setCouponCode,
        discountAmount, setDiscountAmount,
        isCouponValid, setIsCouponValid,
        couponDetails, setCouponDetails,
        isPlanningTime, setIsPlanningTime,
        updateUserDetails
      }}>
      <Helmet>
        <title>{capitalizeWords(restaurantId)} - Online shop</title>
        <meta name="description" content={"Online shop"} />
      </Helmet>

      <Flex className={styles.shop} h={"100dvh"}>
        
        <ShopHeader
          restaurantId={restaurantId}
          logoUrl={settings.logo.shop}
          menuUrl={settings.menuUrl}
          styles={styles}
          selectedLanguage={selectedLanguage}
          secondaryLanguages={settings.secondaryLanguages}
          handleLanguageChange={handleLanguageChange}
        />

        {children}
        
      </Flex>
    </ShopContext.Provider>
  );
};
