import { Link } from "react-router-dom";
import { Flex, Button, Tooltip } from "@chakra-ui/react";
import { useAuth } from "../context/AuthContext";
import UserProfile from "./account/UserProfile";
import { SocialIcon } from 'react-social-icons';
import { useShopContext } from "../context/ShopContext";
import { useEffect, useState } from "react";

const ShopHeader = ({ styles, logoUrl, restaurantId }) => {
  const { checkAuth, currentUser, loading } = useAuth();
  const { shopSettings } = useShopContext();
  const [isLoading, setIsLoading] = useState(true);

  // Check auth state on component mount
  useEffect(() => {
    const verifyAuth = async () => {
      try {
        await checkAuth(); // Force explicit check of auth state
      } catch (error) {
        console.error("No user authenticated", error);
      } finally {
        setIsLoading(false);
      }
    };

    verifyAuth();
  }, [checkAuth]);

  if (isLoading || loading) {
    return <div>Loading...</div>; // You can replace this with a Spinner or loading component
  }

  return (
    <Flex as="header" className={styles.shopHeader} zIndex={9999999}>
      <div className={styles.container}>
        <Link
          to={`/${restaurantId}/shop`}
          className={styles.logo}
          title="Back to home"
        >
          <img src={logoUrl} alt="Logo" />
        </Link>

        <Flex direction="row" gap={1} alignItems={"center"}>
          <Flex gap={1}>
            {shopSettings?.contact?.social?.instagram && (
              <Tooltip label="Follow us on Instagram">
                <SocialIcon
                url={`https://instagram.com/${shopSettings.contact.social?.instagram}`}
                style={{ width: "32px", height: "32px" }}
                target="_blank"
              />
              </Tooltip>
            )}
            {shopSettings?.contact?.social?.facebook && (
              <Tooltip label="Follow us on Facebook">
                <SocialIcon
                url={`https://facebook.com/${shopSettings.contact.social?.facebook}`}
                style={{ width: "32px", height: "32px" }}
                target="_blank"
              /></Tooltip>
            )}
            {shopSettings?.contact?.social?.x && (
               <Tooltip label="Follow us on X">
                <SocialIcon
                url={`https://twitter.com/${shopSettings.contact.social?.x}`}
                style={{ width: "32px", height: "32px" }}
                target="_blank"
              /></Tooltip>
            )}
          </Flex>

          {currentUser ? (
            <UserProfile />
          ) : (
            <Button as={Link} to="/login" variant="outline">
              Login
            </Button>
          )}
        </Flex>
      </div>
    </Flex>
  );
};

export default ShopHeader;
