import React, { useEffect } from "react";
import { Flex, Button, Text, Box } from "@chakra-ui/react";
import useCoupons from "../../../hooks/useCoupons";

const Coupon = ({ restaurantId, discountAmount }) => {
  const { couponCode, setCouponCode, handleApplyCoupon, userCoupons } =
    useCoupons(restaurantId);

  const handleSelectCoupon = (coupon) => {
    setCouponCode(coupon.name);
  };

  useEffect(() => {
    if (couponCode) {
      handleApplyCoupon();
    }
  }, [couponCode]); // This will apply the coupon whenever the couponCode changes

  return (
    <>
      {userCoupons.length > 0 && (
        <Flex direction="column" background="white" p={8} gap={4}>
          <Box mt={4}>
            <Text fontWeight="bold">Available Coupons:</Text>
            <Flex direction="column" gap={2}>
              {userCoupons.map((coupon) => (
                <Button
                  key={coupon.id}
                  onClick={() => handleSelectCoupon(coupon)}
                  variant="outline"
                  colorScheme={couponCode === coupon.name ? "green" : "blue"}>
                  {coupon.name} - {coupon.couponValue}
                </Button>
              ))}
            </Flex>
          </Box>
        </Flex>
      )}
    </>
  );
};

export default Coupon;
