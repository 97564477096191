// ShopActivationRoute.js
import { useState, useEffect } from 'react';
import useSettings from '../components/frontend/hooks/useSettings';
import NotFound from '../NotFound';
import { useShopContext } from './context/ShopContext';
import Spinner from "../components/Spinner";
import ShopList from './components/ShopList';
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify"; // Importation de react-toastify
import { DateTime } from 'luxon';
import useAvailableSlots from '../hooks/useAvailableSlots';


const ShopActivationRoute = ({ restaurantId }) => {
  
  const { cartItems, setCartItems, updateItemQuantity, addItemToCart, cartUpdated, 
    setCartUpdated, calculateTotal, clearCart, styles, frontendTags, menuItems, subCategories } = useShopContext(restaurantId); 

  const { availableSlots, nextOpeningDate } = useAvailableSlots(restaurantId);

  const { isLoading } = useSettings(restaurantId);
  const { modules } = useShopContext();

  const [toastDisplayed, setToastDisplayed] = useState(false);

  const showToast = () => {
    toast.info(
      `The restaurant is closed today and will reopen on ${nextOpeningDate.setLocale('en').toLocaleString(DateTime.DATE_FULL)}. You can place your order starting the day before.`,
      {
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true, 
        pauseOnHover: false,
        draggable: false,
        type: "warning",
        icon: false,
        closeButton: false,
      }
    );
  };
  

  // Utilisation de useEffect pour éviter de déclencher plusieurs fois le toast
  useEffect(() => {
    if (availableSlots.length === 0 && nextOpeningDate && !toastDisplayed) {
      showToast(); // Afficher le toast
      setToastDisplayed(true); // Marquer le toast comme affiché
    }
  }, [availableSlots, nextOpeningDate, toastDisplayed]);

  
  if (isLoading) {
    return <Spinner/>; 
  } else if ( modules?.shop) {
    return <><ToastContainer
    autoClose={3000}
    hideProgressBar={true}
    closeOnClick
    theme="colored"
    position="top-center"
/>
<ShopList restaurantId={restaurantId}
    menuItems={menuItems} 
    subCategories={subCategories.filter(cat => !cat.isUncategorized)}
    styles={styles}
    tags={frontendTags}
    addItemToCart={addItemToCart}
    cartUpdated={cartUpdated}
    setCartUpdated={setCartUpdated}
    cartItems={cartItems}
    setCartItems={setCartItems}
    updateItemQuantity={updateItemQuantity}
    calculateTotal={calculateTotal}
    clearCart={clearCart}  /></>;
  } else {
    return <NotFound restaurantId={restaurantId} />;
  }
};

export default ShopActivationRoute;
